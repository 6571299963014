/** @jsxImportSource theme-ui */

import * as React from 'react';
import { Grid, Heading } from "theme-ui";
import { graphql } from "gatsby";

import Seo from "../components/seo";
import PageLayout from "../components/PageLayout";
import BlogPostCard from "../components/BlogPostCard";
import { IPost } from "../components/FeaturedPosts/FeaturedPosts.types";

const Blog: React.FC = ({ data }) => {

  const posts = data.allMarkdownRemark.nodes;

  return (
    <PageLayout>
      <div
        sx={{
          pt: [2, 4],
          pb: [2, 4]
        }}
      >
        <Seo title="Blog - Malik Wahab"/>
        <Heading as="h1">My Thoughts</Heading>
        <p>Opinionated views about new things I learn</p>
      </div>
      <Grid
        gap={3}
        sx={{
          gridTemplateColumns: [`1fr`, `1fr`, `repeat(2, 1fr)`]
        }}
      >
        {posts.map((post: IPost) =>
          <BlogPostCard post={post} key={post.fields.slug} />
        )}
      </Grid>
    </PageLayout>
  );
}

export default Blog;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
          featured
        }
      }
    }
   }
`
