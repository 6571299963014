import * as React from "react";
import {IPost} from "../FeaturedPosts/FeaturedPosts.types";
import {Link} from "gatsby";
import {Card, Heading} from "theme-ui";

const BlogPostCard: React.FC<{ post: IPost }> = ({ post, ...props}) => {

  const paragraphMaxLength = 150;
  const description = post?.frontmatter?.description || post?.excerpt;
  const postParagraph = description?.substring(0, paragraphMaxLength);
  const isLongDescription =  description  && description?.length > paragraphMaxLength;

  return (
    <Card
      as={Link}
      to={post?.fields?.slug}
      variant="post"
      {...props}
    >
      <article
        itemScope
        itemType="http://schema.org/Article"
      >
        <Heading as="h3" variant='heading'>
          <span itemProp="headline">{post?.frontmatter?.title}</span>
        </Heading>
        <section itemProp="description">
          <p sx={{ color: `heading` }}>
            {postParagraph}
            {isLongDescription && `...`}
          </p>
        </section>
      </article>
    </Card>
  )
}

export default BlogPostCard;
